<template>
  <div>
    <!-- Preloader -->
    <!-- Navbar -->

    <!-- Main content -->
    <Contact/>
    <section class="row-center-1">
      <div class="container">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
          <p class="h3" style="text-align: center;padding: 24px 0;">信创认证</p>
          <div class="row" style="text-align: center;margin-bottom: 5vh;">
            <div class="col-md-3 col-lg-3 col-md-3 col-xl-3">
              <a href="@/img/about/xc1.png" target="_blank">
                <img class="img-fluid shadow rounded imghover" src="@/img/about/xc1.png"/>
              </a>
              <div class="mt-3">
                <h5 class="h6 mb-0">达梦认证证书</h5>
              </div>
            </div>
            <div class="col-md-3 col-lg-3 col-md-3 col-xl-3">
              <a href="@/img/about/xc2.png" target="_blank">
                <img class="img-fluid shadow rounded imghover" src="@/img/about/xc2.png"/>
              </a>
              <div class="mt-3">
                <h5 class="h6 mb-0">飞腾认证</h5>
              </div>
            </div>
            <div class="col-md-3 col-lg-3 col-md-3 col-xl-3">
              <a href="@/img/about/xc6.jpg" target="_blank">
                <img class="img-fluid shadow rounded imghover" src="@/img/about/xc6.jpg"/>
              </a>
              <div class="mt-3">
                <h5 class="h6 mb-0">麒麟ARM认证</h5>
              </div>
            </div>
            <div class="col-md-3 col-lg-3 col-md-3 col-xl-3">
              <a href="@/img/about/xc7.jpg" target="_blank">
                <img class="img-fluid shadow rounded imghover" src="@/img/about/xc7.jpg"/>
              </a>
              <div class="mt-3">
                <h5 class="h6 mb-0">麒麟x86认证证书</h5>
              </div>
            </div>
<!--            <div class="col-md-3 col-lg-3 col-md-3 col-xl-3">-->
<!--              <a href="@/img/about/xc3.png" target="_blank">-->
<!--                <img class="img-fluid shadow rounded imghover" src="@/img/about/xc3.png"/>-->
<!--              </a>-->
<!--              <div class="mt-3">-->
<!--                <h5 class="h6 mb-0">光合成员</h5>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-md-3 col-lg-3 col-md-3 col-xl-3">-->
<!--              <a href="@/img/about/xc4.png" target="_blank">-->
<!--                <img class="img-fluid shadow rounded imghover" src="@/img/about/xc4.png"/>-->
<!--              </a>-->
<!--              <div class="mt-3">-->
<!--                <h5 class="h6 mb-0">海光CPU认证</h5>-->
<!--              </div>-->
<!--            </div>-->
          </div>
<!--          <div class="row" style="text-align: center;margin-bottom: 5vh;">-->
<!--            <div class="col-md-3 col-lg-3 col-md-3 col-xl-3">-->
<!--              <a href="@/img/about/xc5.jpg" target="_blank">-->
<!--                <img class="img-fluid shadow rounded imghover" src="@/img/about/xc5.jpg"/>-->
<!--              </a>-->
<!--              <div class="mt-3">-->
<!--                <h5 class="h6 mb-0">海光产品兼容性认证</h5>-->
<!--              </div>-->
<!--            </div>-->


<!--          </div>-->
        </div>
      </div>
    </section>
    <section class="slice slice-lg pb-5" style="padding:0;">
      <div class="container">
        <!-- Section title -->
        <div class="row mb-5 justify-content-center text-center">
          <div class="col-lg-8 col-md-10">
            <h2 class=" mt-4">合作伙伴</h2>
          </div>
        </div>
        <div class="row">
<!--          <div class="col-lg-4 col-sm-6 mb-5 flex_jc">-->
<!--            <div data-animate-hover="2">-->
<!--              <div class="animate-this">-->
<!--                <a href="#">-->
<!--                  <img alt="Image placeholder" class="img-fluid rounded shadow aboutimg" src="@/img/partners/1.png">-->
<!--                </a>-->
<!--              </div>-->
<!--              <div class="mt-3">-->
<!--                <h5 class="h6 mb-0">斯贝达</h5>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <div class="col-lg-4 col-sm-6 mb-5 flex_jc">
            <div data-animate-hover="2">
              <div class="animate-this">
                <a href="#">
                  <img alt="Image placeholder" class="img-fluid rounded shadow aboutimg" src="@/img/partners/2.png">
                </a>
              </div>
              <div class="mt-3">
                <h5 class="h6 mb-0">达梦数据库</h5>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 mb-5 flex_jc">
            <div data-animate-hover="2">
              <div class="animate-this">
                <a href="#">
                  <img alt="Image placeholder" class="img-fluid rounded shadow aboutimg" src="@/img/partners/3.png">
                </a>
              </div>
              <div class="mt-3">
                <h5 class="h6 mb-0">Wis3D</h5>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 mb-5 flex_jc">
            <div data-animate-hover="2">
              <div class="animate-this">
                <a href="#">
                  <img alt="Image placeholder" class="img-fluid rounded shadow aboutimg" src="@/img/partners/4.png">
                </a>
              </div>
              <div class="mt-3">
                <h5 class="h6 mb-0">DaoCloud</h5>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 mb-5 mb-sm-0 flex_jc">
            <div data-animate-hover="2">
              <div class="animate-this">
                <a href="#">
                  <img alt="Image placeholder" class="img-fluid rounded shadow aboutimg" src="@/img/partners/5.png">
                </a>
              </div>
              <div class="mt-3">
                <h5 class="h6 mb-0">中北大学</h5>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 mb-5 mb-sm-0 flex_jc">
            <div data-animate-hover="2">
              <div class="animate-this">
                <a href="#">
                  <img alt="Image placeholder" class="img-fluid rounded shadow aboutimg" src="@/img/partners/6.png">
                </a>
              </div>
              <div class="mt-3">
                <h5 class="h6 mb-0">天喻软件</h5>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 mb-5 mb-sm-0 flex_jc">
            <div data-animate-hover="2">
              <div class="animate-this">
                <a href="#">
                  <img alt="Image placeholder" class="img-fluid rounded shadow aboutimg" src="@/img/partners/7.png">
                </a>
              </div>
              <div class="mt-3">
                <h5 class="h6 mb-0">华为云</h5>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 mb-0 flex_jc">
            <div data-animate-hover="2">
              <div class="animate-this">
                <a href="#">
                  <img alt="Image placeholder" class="img-fluid rounded shadow aboutimg" src="@/img/partners/8.png">
                </a>
              </div>
              <div class="mt-3">
                <h5 class="h6 mb-0">中科海光</h5>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 mb-0 flex_jc">
            <div data-animate-hover="2">
              <div class="animate-this">
                <a href="#">
                  <img alt="Image placeholder" class="img-fluid rounded shadow aboutimg" src="@/img/partners/9.png">
                </a>
              </div>
              <div class="mt-3">
                <h5 class="h6 mb-0">Suma</h5>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 mb-0 flex_jc">
            <div data-animate-hover="2">
              <div class="animate-this">
                <a href="#">
                  <img alt="Image placeholder" class="img-fluid rounded shadow aboutimg" src="@/img/partners/10.png">
                </a>
              </div>
              <div class="mt-3">
                <h5 class="h6 mb-0">麒麟软件</h5>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 mb-0 flex_jc">
            <div data-animate-hover="2">
              <div class="animate-this">
                <a href="#">
                  <img alt="Image placeholder" class="img-fluid rounded shadow aboutimg2" height="116"
                       src="@/img/partners/11.png"
                       width="96">
                </a>
              </div>
              <div class="mt-3">
                <h5 class="h6 mb-0">中国航天</h5>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 mb-0 flex_jc">
            <div data-animate-hover="2">
              <div class="animate-this">
                <a href="#">
                  <img alt="Image placeholder" class="img-fluid rounded shadow aboutimg" src="@/img/partners/12.png">
                </a>
              </div>
              <div class="mt-3">
                <h5 class="h6 mb-0">飞腾</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer/>
    <!-- Core JS  -->
  </div>
</template>
<!--<script src="assets/libs/jquery/dist/jquery.min.js"></script>-->
<!--<script src="assets/libs/bootstrap/dist/js/bootstrap.bundle.min.js"></script>-->
<!--<script src="assets/libs/svg-injector/dist/svg-injector.min.js"></script>-->
<!--<script src="assets/libs/feather-icons/dist/feather.min.js"></script>-->
<!--&lt;!&ndash; Quick JS &ndash;&gt;-->
<!--<script src="assets/js/quick-website.js"></script>-->
<!-- Feather Icons -->
<script>

export default {

}

</script>
<style scoped>
.aboutimg {
  width: 216px;
  /* 	height: 80px; */
}

.aboutimg:hover {
  transition: 1s;
  transform: scale(1.1);
}

.aboutimg2:hover {
  transition: 1s;
  transform: scale(1.1);
}

.flex_jc {
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 5vh;
}

.imghover:hover {
  transition: 1s;
  transform: scale(1.1);
}

.imghover {
  height: 350px !important;
}

.row-center-1 {
  padding-bottom: 5vh;
  margin: auto;
  background: url('../img/login/6.jpg') no-repeat;
  background-size: 100% 100%;
}

/* 悬浮样式 */
.white_content {
  position: fixed;
  right: 10px;
  bottom: 200px;
  z-index: 999;
  background: none;
}

.white_content-1 {
  background: #50b5ff;
  padding: 10px 20px;
  border-radius: 30%;
  text-align: center;
  color: #171347;
}
</style>
